import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sliderMain from "../images/Headerbgmain.jpg";
import sliderOne from "../images/headerbg.jpg";
import sliderTwo from "../images/headerbg4.png";
import sliderThree from "../images/headerbg3.png";
import sliderFour from "../images/ccsa.png";
import CNSALogo from "../images/Group 10.svg";
import CPSALogo from "../images/Group 11.svg";
import CWSALogo from "../images/Group 12.svg";
import CCSALogo from "../images/Group 13.svg";
import extantion from "../images/extention.svg";

const slides = [
  { image: sliderMain, src: extantion },
  {
    image: sliderOne,
    src: CNSALogo,
    description: "Continuous Nitrogen Services Arabia",
    buttonText: "Learn More",
    buttonLink: "/services-cnsa",
  },
  {
    image: sliderTwo,
    src: CPSALogo,
    description: "Continuous Pipeline Services Arabia",
    buttonText: "Learn More",
    buttonLink: "/cpsa",
  },
  {
    image: sliderThree,
    src: CWSALogo,
    description: "Continuous Water Services Arabia",
    buttonText: "Learn More",
    buttonLink: "/cwsa",
  },
  {
    image: sliderFour,
    src: CCSALogo,
    description: "Continuous Chemical Services Arabia",
    buttonText: "Learn More",
    buttonLink: "/ccsa",
  },
];

const Header = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % slides.length);
    }, 9000);
    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  return (
    <div
      className="relative h-screen bg-cover bg-center transition-opacity duration-1000"
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${slides[currentImage].image}) lightgray 50% / cover no-repeat`,
      }}
    >
      <div className="absolute inset-0 flex flex-col items-center justify-center h-full px-[16px]">
        {slides[currentImage].src && (
          <img src={slides[currentImage].src} alt="Logo" />
        )}

        <p className="w-auto my-[2rem] text-white text-[16px] text-center md:text-[18px] lg:w-[832px] lg:text-[20px] xl:w-[832px] xl:text-[20px]">
          {slides[currentImage].description}
        </p>

        {slides[currentImage].buttonText && slides[currentImage].buttonLink && (
          <button
            onClick={() => navigate(slides[currentImage].buttonLink)}
            className="buttonTwo"
          >
            <span className="buttonText">{slides[currentImage].buttonText}</span>
          </button>
        )}

        <div className="flex justify-center mt-[2rem] gap-[24px]">
          {slides.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-[40px] h-[6px] rounded-full mx-1 cursor-pointer transition-all duration-300 ${
                currentImage === index ? "bg-primary" : "bg-[#FFF]"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
